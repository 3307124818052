import React, { useEffect } from 'react';
import ErrorPage from '../ErrorPage';
import Loader from '~components/Loader';
import useGetTemplate from '~hooks/useGetTemplate';
import { useCampaign } from '~Flow';
import useSetLinks from '~hooks/useSetLinks';
import DangerouslySetHtmlContent from '~components/DangerouslySetHtmlContent';
import useQuery from '~hooks/useQuery';

const LandingPage = () => {
  const { landingHtml, termsModalHtml, error, loading } = useGetTemplate();
  const { provider } = useQuery();
  const { campaign, url, setProvider } = useCampaign();
  useSetLinks(loading);

  useEffect(() => {
    setProvider(provider);
  }, []);

  if (error) return <ErrorPage errorCode={error} />;

  if (loading) return <Loader />;

  return (
    <DangerouslySetHtmlContent
      html={landingHtml
        .replaceAll('{{campaign-theme}}', campaign.Name)
        .replaceAll('{{campaign-survey-url}}', `${url}/survey`)
        .replaceAll('{{terms-modal}}', termsModalHtml)}
    />
  );
};

export default LandingPage;
