import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCampaign } from '~Flow';
import PremiumLogoEn from '~images/Premium-Encuestas_logo_ENG.png';
import PremiumLogoEs from '~images/Premium-Encuestas_logo_ESP.png';
import PremiumLogoPt from '~images/Premium-Encuestas_logo_POR.png';
import SpinnerMan from '~images/premium_spinner.png';
import Checking from '~images/premium_checking.png';
import Checked from '~images/premium_checked.png';
import './styles.scss';

const AwaitPremium = () => {
  const [stepChecked, setStepChecked] = useState(0);

  const {
    campaign,
    url,
    userData: {
      firstname = '',
      lastname = '',
      email = '',
      dateOfBirth = '',
      sex = '',
      country = '',
      district = '',
      localization = '',
      city = '',
      zipcode = '',
    },
    provider = '',
  } = useCampaign();
  const history = useHistory();
  const { t } = useTranslation('common');

  useEffect(() => {
    let timer;
    if (stepChecked <= 3) {
      timer = setTimeout(() => setStepChecked(prev => prev + 1), 1500);
    }
    if (stepChecked === 3) {
      timer = setTimeout(() => {
        if (campaign['Mostrar ending']) {
          history.push(`${url}/ending`);
        } else {
          window.location.href = campaign['Redirect URL']
            .replace('{{provider}}', provider || '')
            .replace('{{firstname}}', encodeURIComponent(firstname))
            .replace('{{lastname}}', encodeURIComponent(lastname))
            .replace('{{email}}', encodeURIComponent(email))
            .replace('{{gender}}', encodeURIComponent(sex))
            .replace('{{dateOfBirth}}', encodeURIComponent(dateOfBirth))
            .replace('{{country}}', encodeURIComponent(country))
            .replace('{{district}}', encodeURIComponent(district))
            .replace('{{city}}', encodeURIComponent(city))
            .replace('{{zipcode}}', encodeURIComponent(zipcode))
            .replace('{{localizationId}}', encodeURIComponent(localization));
        }
      }, 1500);
    }

    return () => clearTimeout(timer);
  }, [stepChecked]);

  return (
    <div className="awaitPremium">
      <header className="headerPremium">
        <div className="headerPremium__container">
          {campaign.Language === 'en' && (
            <img src={PremiumLogoEn} className="headerPremium__logo" alt="" />
          )}
          {campaign.Language === 'es' && (
            <img src={PremiumLogoEs} className="headerPremium__logo" alt="" />
          )}
          {campaign.Language === 'pt-br' && (
            <img src={PremiumLogoPt} className="headerPremium__logo" alt="" />
          )}
        </div>
      </header>
      <main className="awaitPremium__main">
        <div className="awaitPremium__container">
          <img src={SpinnerMan} alt="" width={203} height={233} />
          <div className="awaitPremium__progressBar">
            <div className="awaitPremium__progress" />
          </div>

          <div className="awaitPremium__step">
            <p className="awaitPremium__step--text">{t('awaitPremium.step1')}</p>
            <img
              className={stepChecked < 1 ? 'awaitPremium__spinning' : ''}
              src={stepChecked < 1 ? Checking : Checked}
              alt=""
            />
          </div>
          <div className="awaitPremium__step">
            <p className="awaitPremium__step--text">{t('awaitPremium.step2')}</p>
            <img
              className={stepChecked < 2 ? 'awaitPremium__spinning' : ''}
              src={stepChecked < 2 ? Checking : Checked}
              alt=""
            />
          </div>
          <div className="awaitPremium__step">
            <p className="awaitPremium__step--text">{t('awaitPremium.step3')}</p>
            <img
              className={stepChecked < 3 ? 'awaitPremium__spinning' : ''}
              src={stepChecked < 3 ? Checking : Checked}
              alt=""
            />
          </div>
        </div>
      </main>
      <footer className="footer-premium">
        <p>
          © {new Date().getFullYear()} {t('premiumName')}
        </p>
      </footer>
    </div>
  );
};

export default AwaitPremium;
