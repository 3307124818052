import axios from 'axios';
import nocodb from './nocodb';

const TABLE_ID = process.env.NOCODB_SURVEY_TABLE_ID;

export const getSurveys = async surveysIds => {
  const surveyQueries = surveysIds.map(id => `(ncRecordId,eq,${id})`);
  const query = new URLSearchParams({ where: surveyQueries.join('~or') });
  return nocodb.get(`/tables/${TABLE_ID}/records?${query.toString()}`).then(({ data }) => data);
};

export const putSurveyAnswers = async body => axios.put('/panelist', body);

// localizationTypeId para zipCodes es 26
export const getLocalizationByName = async (localizationName, localizationTypeId = 26) =>
  axios.get(`/localizationType/${localizationTypeId}/name/${localizationName}`);
