import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCampaign } from '~Flow';
import api from '~api';
import handleSurveys from '~lib/handleSurveys';

const useGetTemplate = () => {
  const [landingHtml, setLandingHtml] = useState('');
  const [endingHtml, setEndingHtml] = useState('');
  const [endingNoRedirectHtml, setEndingNoRedirectHtml] = useState('');
  const [termsModalHtml, setTermsModalHtml] = useState('');
  const [survey, setSurvey] = useState([]);
  const [registerFields, setRegisterFields] = useState([]);
  const [registerId, setRegisterId] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { i18n } = useTranslation();

  const { campaign, campaignLoading, campaignError } = useCampaign();

  useEffect(() => {
    (async () => {
      if (
        !campaignLoading &&
        !campaignError &&
        campaign &&
        Object.keys(campaign).length &&
        (!landingHtml || !endingHtml || !endingNoRedirectHtml || !survey || !registerFields)
      ) {
        try {
          setLoading(true);
          const [surveysResp, landing, ending, endingNoRedirect, termsModal] = await Promise.all([
            api.getSurveys(campaign.Survey),
            api.getTemplate(campaign.Landing),
            api.getTemplate(campaign.Ending),
            api.getTemplate(campaign['Ending no redirect']),
            api.getTemplate(campaign['Terms Modal']),
          ]);

          const { surveyJson, register, registerSurveyId } = handleSurveys(
            surveysResp.list,
            campaign.Survey,
          );
          setSurvey(surveyJson);
          if (register) {
            setRegisterFields(register);
            setRegisterId(registerSurveyId);
          }

          setLandingHtml(landing.Content);
          setEndingHtml(ending.Content);
          setEndingNoRedirectHtml(endingNoRedirect.Content);
          setTermsModalHtml(termsModal.Content);

          i18n.changeLanguage(campaign.Language);
        } catch (err) {
          setError(err.statusCode);
          console.warn(err.message);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [campaign, campaignLoading, campaignError]);

  return {
    landingHtml,
    endingHtml,
    endingNoRedirectHtml,
    termsModalHtml,
    surveyJson: survey,
    registerFields,
    registerId,
    error: campaignError || error,
    loading,
  };
};

export default useGetTemplate;
